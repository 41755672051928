<template>
   <div class="row">
		<div id="orcamento-tab">
			<div class="row">
				<div id="search-nav" class="col-12 px-0 d-block btn-group dropup">
					<button type="button" class="btn btn-primary dropdown-toggle d-block d-lg-none" data-bs-toggle="dropdown" data-bs-auto-close="false" aria-expanded="false">
						<i class="far fa-file font-17 mx-auto"></i><i class="fal fa-times font-17 mx-auto"></i>
					</button>
					<div class="card mb-1 dropdown-menu dropdown-menu-left">
						<div class="card-body p-3 pb-0">
							<div class="row">
                        <div class="col-12 mb-3">
                           <label><i class="far fa-store color-theme font-11 me-1"></i> Loja</label>
                           <v-select name="Desktop" :options="dadosUsuario.usuarioLojas" v-model="pesquisa.idLoja" label="nomeFantasia" :reduce="loja => loja.id" :clearable="false" placeholder="Todas as lojas" @input="salvarOrcamento">
                              <template #option="option">{{ option.nomeFantasia }}<small class="mx-2"><i class="fal fa-grip-lines-vertical"></i></small><i class="far fa-tag font-11 me-1"></i> {{ option.numeroLoja }}</template>
                              <template v-slot:no-options>
                                 <span class="d-block lh-sm my-1">Sem resultados</span><small class="d-block lh-sm mb-1 opacity-90 font-11">Solicite permissão ou utilize a tela "Lojas" para adicionar</small>
                              </template>
                           </v-select>
                           <select name="Mobile" class="form-control" v-model="pesquisa.idLoja" @change="salvarOrcamento">
                              <option v-for="(option, index) in dadosUsuario.usuarioLojas" :key="index" :value="option.id">{{ option.nomeFantasia }}&nbsp;&nbsp;|&nbsp;&nbsp;nº {{ option.numeroLoja }}</option>
                           </select>
                        </div>
                        <div class="col-12 mb-3">
                           <label><i class="far fa-user color-theme font-10 me-1"></i> Cliente</label>
                           <div class="input-group">
                              <div class="input-group-text">
                                 <i class="fal cursor-pointer" :class="config.searchCliente ? 'fa-undo' : 'fa-search'" @click="config.searchCliente = !config.searchCliente"
                                    :title="config.searchCliente ? 'Voltar' : 'Buscar'"></i>
                              </div>
                              <input v-if="!config.searchCliente" @click="config.searchCliente = true;" :value="pesquisa.cpfCnpjCliente" title="Buscar cliente" 
                                 type="text" class="form-control cursor-pointer bg-transparent" readonly placeholder="Selecione uma opção" />
                              <autoCompleteDestinatario v-else class="col" :tipo="'Cliente'" @submit="setClienteTemp($event)" />
                           </div>
                        </div>
                        <div class="col-12 mb-3">
                           <label><i class="far fa-receipt color-theme font-10 me-1"></i> Nome</label>
                           <input type="text" class="form-control" v-model="pesquisa.nome" maxlength="200" @blur="salvarOrcamento" />
                        </div>
								<div class="col-6 mb-3 align-self-center" v-if="pesquisa.codigo">
									<label class="mb-0"><i class="far fa-hashtag color-theme font-11 me-1"></i> Localizador:</label>
								</div>
								<div class="col-6 mb-3 text-end" v-if="pesquisa.codigo">{{ pesquisa.codigo }}</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-12 px-0">
					<div class="card mb-1">
						<div class="card-body py-12">
							<div class="row align-items-center">
								<div class="col-6 col-md-3 col-lg-6">
									<strong class="subtitle font-14 me-1">Total</strong>
								</div>
								<div class="col-6 col-md-3 col-lg-6 text-end text-md-start text-lg-end">
									<span class="font-15"><small>R$ </small>{{ parseFloat(pesquisa.valor).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits:2}) }}</span>
								</div>
								<div class="col ps-0 pe-1 mt-2 mt-md-0 mt-lg-2" @click="removerOrcamento" v-if="pesquisa.id != null">
									<button class="btn border-red text-red"><i class="fal fa-times me-1"></i> Descartar</button>
								</div>
								<div class="col-6 col-md-3 col-lg-6 px-0 mt-2 mt-md-0 mt-lg-2" @click="imprimir" v-if="pesquisa.itens.length > 0">
									<button class="btn border-secondary text-secondary"><i class="fal fa-print me-1 font-12"></i> Imprimir</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="col-lg px-lg-0">
			<div class="row">
				<div class="col-12 px-0" v-if="pesquisa.idLoja && pesquisa.cpfCnpjCliente">
					<div class="card mb-1">
						<div class="card-body p-3 pb-0">
							<div class="row">
								<div class="col-md-6 mb-3">
									<label><i class="far fa-apple-alt color-theme font-11 me-1"></i> Adicionar produto *</label>
									<autoCompleteProduto :loja="pesquisa.idLoja != null ? pesquisa.idLoja : null" @submit="setProdutoTemp($event)" />
								</div>
								<div class="col-5 col-md-2 mb-3">
									<label><i class="far fa-box color-theme font-10 me-1"></i> Quantidade *</label>
									<input type="text" class="form-control text-end" ref="pesquisaProdutoQtd" v-model="pesquisaProduto.quantidade" @keyup.enter="$refs.pesquisaProdutoCusto.focus()" />
								</div>
								<div class="col-5 col-md-2 mb-3">
									<label><i class="far fa-dollar-sign color-theme font-10 me-1"></i> Preço Un. *</label>
									<input type="text" class="form-control text-end" ref="pesquisaProdutoCusto" v-model="pesquisaProduto.preco" @keyup.enter="salvarOrcamentoProduto(null)" />
								</div>
								<div class="col-2 col-md-2 mb-3 align-self-end">
									<button type="button" class="btn btn-primary w-100" @click="salvarOrcamentoProduto(null)">
										<i class="far fa-plus font-13"></i><span class="ms-1 d-none d-xl-inline"> Adicionar</span>
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-12 px-0 text-center" v-if="pesquisa.itens.length == 0">
					<div class="card">
						<div class="card-body py-5" v-if="pesquisa.idLoja == null">
                     <i class="fal fa-store font-60 text-secondary opacity-50 d-block mb-3"></i>
							<h4 class="font-14 mt-4 mb-0 text-secondary text-uppercase">1º Passo</h4>
							<h4 class="font-12 mt-2 mb-0 text-secondary text-uppercase">Selecione uma loja</h4>
						</div>
						<div class="card-body py-5" v-else-if="pesquisa.cpfCnpjCliente == null">
							<i class="fal fa-envelope font-60 text-secondary opacity-50 d-block mb-3"></i>
							<h4 class="font-14 mt-4 mb-0 text-secondary text-uppercase">2º Passo</h4>
							<h4 class="font-12 mt-2 mb-0 text-secondary text-uppercase">Selecione um cliente</h4>
						</div>
						<div class="card-body py-5" v-else>
							<i class="fal fa-box-open font-60 text-secondary opacity-50 d-block mb-3"></i>
							<h4 class="font-12 mt-4 mb-0 text-secondary text-uppercase">Nenhum produto encontrado</h4>
						</div>
					</div>
				</div>

				<div class="col-12 px-0" v-if="pesquisa.itens.length > 0">
					<produto v-for="(produto, index) in pesquisa.itens" :key="index" :produto="produto" :index="index" @salvar="salvarOrcamentoProduto($event)" />
				</div>
			</div>
		</div>

		<impressao id="impressao" class="d-none" :titulo="$route.name.replace(/_/g, ' ').replace(/-/g, '/')" :data="pesquisa.data" :cabecalho="{
			'Nome orçamento': pesquisa.nome, 
			'Localizador': pesquisa.codigo, 
			'Loja': pesquisa.nomeLoja +' / Nº '+ pesquisa.numeroLoja, 
			'Cliente - Nome': pesquisa.nomeCliente, 
			'Cliente - CPF/CNPJ': pesquisa.cpfCnpjCliente, 
			'Quant. itens': pesquisa.itens.length, 
			}" :itensKey="{'nome': 'Descrição', 'quantidade': 'Quant.', 'preco': 'Preço'}" :itens="pesquisa.itens" :total="pesquisa.valor" />
	</div>
</template>

<script>

import Swal from 'sweetalert2'
import { mapState } from 'vuex'
import produto from '@/components/pdv/orcamentos/Produto.vue'
import impressao from '@/components/impressao/ComprovanteA4.vue'
import autoCompleteProduto from '@/components/modelos/AutoCompleteProduto.vue'
import autoCompleteDestinatario from '@/components/modelos/AutoCompleteDestinatario.vue'

export default {
   name: 'Orçamento',
   data: function () {
		return {
			pesquisa: {'id': null, 'nomeCliente': null, 'cpfCnpjCliente': null, 'nome': null, 'valor': '0.00', 'itens': [], 'nomeLoja': null, 'numeroLoja': null, 'idLoja': null, 'codigo': null},
         pesquisaProduto: {'id': null, 'idOrcamento': null, 'idProduto': null, 'nome': null, 'quantidade': '1', 'preco': '0.00', 'isDecimal': false},
         config: {'searchCliente': false}
		}
	},
   computed: {
      ... mapState({
         dadosUsuario: state => state.dadosUsuario,
         urlRest: state => state.urlRest,
         tabs: state => state.tabs
      })
   },
   watch: {
		'$route.params.idTab': function () {
			this.buscarOrcamento()
      },
		'pesquisa.itens': {
			handler: function (newVal) {
				this.pesquisa.valor = newVal.reduce((total, p) => total + ((/^[0-9.]+$/.test(p.quantidade) ? parseFloat(p.quantidade) : 0) * (/^[0-9.]+$/.test(p.preco) ? parseFloat(p.preco) : 0)), 0)
			},
			deep: true
      },
      'pesquisaProduto.quantidade': function (newVal) {
         this.$store.dispatch('formatValue', {'valor': newVal, 'digitos': this.pesquisaProduto.isDecimal ? 3 : 0}).then((valor) => {
            this.pesquisaProduto.quantidade = valor
         })
      },
      'pesquisaProduto.preco': function (newVal) {
         this.$store.dispatch('formatValue', {'valor': newVal, 'digitos': 2}).then((valor) => {
            this.pesquisaProduto.preco = valor
         })
      }
	},
   components: {
		produto, impressao, autoCompleteDestinatario, autoCompleteProduto
	},
   methods: {
		imprimir : function () {
         setTimeout(() => this.$htmlToPaper('impressao', {styles: ['./comprovanteA4.css']}), 250);
      },
      buscarOrcamento : function () {
         Object.assign(this.$data.pesquisa, this.$options.data.apply(this).pesquisa)

         if (this.$route.query.id != null && String(this.$route.query.id).trim().length > 0) {
            this.$store.dispatch('alternarTelaCarregamento', true)
   
            this.$axios({
               method: 'get',
               url: this.urlRest +'pdv/getOrcamento',
               params: {
                  id: this.$route.query.id
               }
            }).then(response => {
               this.pesquisa = response.data
   
            }).catch((error) => {
               if (error.response != undefined) {
                  if (error.response.status == 408) {
                     this.$store.dispatch('deslogar')
   
                     this.$toast.fire({
                        icon: 'warning',
                        title: 'Sessão expirada!'
                     });
						} else if (error.response.status == 400) {
							Swal.fire({
								icon: 'error',
								title: 'Orçamento não encontrado',
								text: 'O orçamento que você estava editando pode ter sido excluído.',
								showConfirmButton: false,
								showCancelButton: true,
								cancelButtonText: `Sair`
								
							}).then(() => {
								this.$store.dispatch('deleteTabData', this.tabs.findIndex(tab => tab.id == this.$route.params.idTab))
							})
                  } else {
                     this.$toast.fire({
                        icon: 'error',
                        title: 'Erro: ' + error.response.status
                     });
                  }
               }
            }).finally(() => {
               this.$store.dispatch('alternarTelaCarregamento', false)
            });
         }
		},
      setClienteTemp : function (result) {
         this.pesquisa.cpfCnpjCliente = result.cpfCnpj; 
         this.config.searchCliente = false;
			this.salvarOrcamento()
      },
      setProdutoTemp : function (result) {
         let codigo = result.codigos.find(e => e.ean != null && /^[a-zA-Z0-9]+$/.test(e.ean))
			this.pesquisaProduto = {
            'id': null, 
            'idOrcamento': this.pesquisa.id, 
            'idProduto': result.id, 
            'nome': result.nome, 
            'quantidade': result.isDecimal ? '1.000' : '1', 
            'preco': result.preco == null ? "0.00" : parseFloat(result.preco).toFixed(2), 
            'codigo': codigo != null ? codigo.ean : null,
            'codigos': '',
				'isDecimal': result.isDecimal
         }

			this.$refs.pesquisaProdutoQtd.focus()
		},
		salvarOrcamento : function () {
			if (this.pesquisa.idLoja == null && this.pesquisa.cpfCnpjCliente == null && (this.pesquisa.nome == null || String(this.pesquisa.nome).trim().length == 0)) return;

			this.$axios({
				method: 'post',
				url: this.urlRest +'pdv/saveOrcamento',
				headers: {'Content-Type': 'application/json'},
				data: this.pesquisa

         }).then((response) => {
				this.pesquisa.id = response.data.id
				this.pesquisa.codigo = response.data.codigo

            if (this.pesquisa.id != null) {
               this.$route.query.id = this.pesquisa.id
            }

            if (this.pesquisa.codigo != null) {
               this.$route.query.codigo = this.pesquisa.codigo
            }
			});
		},
		salvarOrcamentoProduto : function (produto) {
			if (produto == null) produto = this.pesquisaProduto;

			if (produto.idProduto == null) return;

			if (!/^[0-9.]+$/.test(produto.quantidade) || !/^[0-9.]+$/.test(produto.preco)) {
				this.$toast.fire({
					icon: 'error',
					title: 'Quantidade/preço unitário inválido'
				});

				return
			}

			if (produto.id == null) this.$store.dispatch('alternarTelaCarregamento', true)

			this.$axios({
				method: 'post',
				url: this.urlRest +'pdv/saveOrcamentoProduto',
				headers: {'Content-Type': 'application/json'},
				data: produto

			}).then(() => {
				Object.assign(this.$data.pesquisaProduto, this.$options.data.apply(this).pesquisaProduto)
				if (produto.id == null) this.buscarOrcamento()

			}).catch((error) => {
				if (error.response != undefined) {
					if (error.response.status == 408) {
						this.$store.dispatch('deslogar')

						this.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
					} else if (error.response.status == 400) {
						Swal.fire({
							icon: 'error',
							title: 'Orçamento não encontrado',
							text: 'O orçamento que você estava editando pode ter sido excluído.',
							showConfirmButton: false,
							showCancelButton: true,
							cancelButtonText: `Sair`
							
						}).then(() => {
							this.$store.dispatch('deleteTabData', this.tabs.findIndex(tab => tab.id == this.$route.params.idTab))
						})
					} else {
						this.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
				}
			}).finally(() => {
				this.$store.dispatch('alternarTelaCarregamento', false)
			});
		},
      removerOrcamento : function () {
         Swal.fire({
            icon: 'warning',
            title: 'Descartar orçamento?',
            text: 'Todas informações preenchidas até o momento serão descartadas.',
            showCancelButton: true,
            confirmButtonText: 'Confirmar',
            cancelButtonText: `Cancelar`
				
         }).then((result) => {
            if (result.isConfirmed) {
					this.$store.dispatch('alternarTelaCarregamento', true)
		
					this.$axios({
						method: 'post',
						url: this.urlRest +'pdv/deleteOrcamento',
                  params: {
                     id: this.pesquisa.id  
                  }
					}).then(() => {
						this.$store.dispatch('deleteTabData', this.tabs.findIndex(tab => tab.id == this.$route.params.idTab))
		
						this.$toast.fire({
							icon: 'success',
							title: 'Orçamento descartado!'
						});
					}).catch((error) => {
						if (error.response != undefined) {
							if (error.response.status == 408) {
								this.$store.dispatch('deslogar')
		
								this.$toast.fire({
									icon: 'warning',
									title: 'Sessão expirada!'
								});
							} else {
								this.$toast.fire({
									icon: 'error',
									title: 'Erro: ' + error.response.status
								});
							}
						}
					}).finally(() => {
						this.$store.dispatch('alternarTelaCarregamento', false)
					});
				}
			})
      }
	},
   mounted() {
		this.buscarOrcamento()
	}
}

</script>

<style scoped>

#orcamento-tab .card .row button {
	line-height: 1;
	padding: 12px 6px;
	text-align: center;
	display: block;
	font-weight: 500;
	text-transform: uppercase;
	font-size: 12px;
	width: 100%;
	border-radius: 3px;
}

@media (min-width: 992px) {
   #orcamento-tab {
      width: 250px;
   }
}

@media (min-width: 1400px) {
   #orcamento-tab {
      width: 305px;
   }
}

</style>